exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-admin-edit-recipe-index-tsx": () => import("./../../../src/pages/admin/edit-recipe/index.tsx" /* webpackChunkName: "component---src-pages-admin-edit-recipe-index-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-cooking-with-family-index-tsx": () => import("./../../../src/pages/cooking-with-family/index.tsx" /* webpackChunkName: "component---src-pages-cooking-with-family-index-tsx" */),
  "component---src-pages-friendship-feists-index-tsx": () => import("./../../../src/pages/friendship-feists/index.tsx" /* webpackChunkName: "component---src-pages-friendship-feists-index-tsx" */),
  "component---src-pages-global-flavours-index-tsx": () => import("./../../../src/pages/global-flavours/index.tsx" /* webpackChunkName: "component---src-pages-global-flavours-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-master-the-basics-index-tsx": () => import("./../../../src/pages/master-the-basics/index.tsx" /* webpackChunkName: "component---src-pages-master-the-basics-index-tsx" */),
  "component---src-pages-privacypolicy-index-tsx": () => import("./../../../src/pages/privacypolicy/index.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-index-tsx" */),
  "component---src-pages-termsandconditions-index-tsx": () => import("./../../../src/pages/termsandconditions/index.tsx" /* webpackChunkName: "component---src-pages-termsandconditions-index-tsx" */),
  "component---src-templates-all-cuisines-template-tsx": () => import("./../../../src/templates/all-cuisines-template.tsx" /* webpackChunkName: "component---src-templates-all-cuisines-template-tsx" */),
  "component---src-templates-cuisine-recipes-template-tsx": () => import("./../../../src/templates/cuisine-recipes-template.tsx" /* webpackChunkName: "component---src-templates-cuisine-recipes-template-tsx" */),
  "component---src-templates-recipe-template-tsx": () => import("./../../../src/templates/recipe-template.tsx" /* webpackChunkName: "component---src-templates-recipe-template-tsx" */)
}

